<template>
  <div>
    <form @submit.prevent="setNote">
      <div class="flix-form-group">
        <div
          class="flix-alert flix-alert-warning"
          v-if="type == 'checkIn' && data.check_in"
        >
          <span
            >{{ $t('message.done') }}: <b>{{ $t('message.checkIn') }}</b></span
          >
        </div>
        <div
          class="flix-alert flix-alert-warning"
          v-if="type == 'checkOut' && data.check_out"
        >
          <span
            >{{ $t('message.done') }}: <b>{{ $t('message.checkOut') }}</b></span
          >
        </div>
        <div class="flix-text-left">
          <label class="flix-html-label" for="notice">{{
            $t('message.add', {
              name: $tc('message.' + type, 2) + ' ' + $tc('message.note', 1)
            })
          }}</label>
        </div>
        <textarea
          class="flix-form-control"
          id="notice"
          :placeholder="
            $tc('message.note', 1) + ' ' + $tc('message.' + type, 2)
          "
          v-model="text"
        ></textarea>
      </div>
      <div class="flix-form-group flix-row">
        <div class="flix-col-md-12">
          <button
            type="submit"
            class="flix-btn flix-btn-block flix-btn-xs flix-btn-success"
          >
            {{ $t('message.save', { name: $tc('message.' + type, 2) }) }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    type: String,
    callback: Function
  },
  data() {
    return {
      text: false
    }
  },
  computed: {},
  mounted() {
    this.getText()
  },
  methods: {
    setNote() {
      if (!this.text.trim().length) {
        return false
      }

      const userID = this.data.atitle
        ? JSON.parse(this.data.atitle).user
        : this.$getUserVariables().user.md5_id

      this.$flix_post({
        url: 'log/save',
        data: {
          user: userID,
          category: this.type.toLowerCase(),
          msg: this.text,
          ID: this.data.ID,
          IP: 'XXX'
        },
        callback: () => {
          const newData = JSON.parse(JSON.stringify(this.data));
          (
            this.type.toLowerCase() === 'checkin'
          )
            ? (newData.check_in = this.text)
            : (newData.check_out = this.text)

          this.callback(newData)
        }
      })
    },
    getText() {
      var date = new Date()
      var txt = ''
      txt += this.$tc('message.' + this.type, 2)
      txt += ' '
      txt += this.$tc('message.customer', 1)
      txt += ' '
      txt += this.data.booking_id
      txt += ' = '
      txt +=
        this.getNullsBefore(date.getDate()) +
        '.' +
        this.getNullsBefore(date.getMonth() + 1) +
        '.' +
        date.getFullYear()
      txt += ', '
      txt +=
        this.getNullsBefore(date.getHours()) +
        ':' +
        this.getNullsBefore(date.getMinutes())
      txt += ' '
      txt += this.$t('message.oClock')
      this.text = txt
    },
    getNullsBefore(int) {
      if (int * 1 < 10) {
        return '0' + int
      }
      return int
    }
  }
}
</script>
<style lang="sass" scoped></style>
